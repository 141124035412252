<template>
	<BaseLayout>
		<Toolbar @clickLeft="$router.go(-1)">
			<template v-slot:start>
				<Icon class="h-5 w-5" icon="angleLeft" />
			</template>
			<p>{{ $t('chat.customerSupport') }}</p>
		</Toolbar>
		<div v-if="messages" class="flex h-screen flex-col justify-end overflow-scroll bg-light-blue px-5 pt-2 pb-24" id="messagesContainer">
			<div v-for="message in messages" :key="message.id">
				<!-- 客戶訊息 -->
				<div v-if="message.user === 'CUSTOMER'" class="flex justify-end">
					<div class="mb-5 flex w-10/12 flex-col gap-3 rounded-xl bg-primary p-3 text-right text-white">
						<div>{{ message.content }}</div>
						<div class="text-xs">{{ getDateTime(message.createdAt) }}</div>
					</div>
				</div>
				<!-- 系統訊息 -->
				<div v-else class="flex justify-start">
					<div class="mb-5 flex w-10/12 flex-col gap-3 rounded-xl bg-white p-3">
						<div class="font-bold">{{ $t('chat.systemInformation') }}</div>
						<div>{{ message.content }}</div>
						<div class="text-xs">{{ getDateTime(message.createdAt) }}</div>
					</div>
				</div>
			</div>
		</div>

		<!-- <Footer > -->
		<Footer>
			<form class="flex w-full justify-around bg-white py-2" @submit.stop.prevent="submit">
				<input v-model="content" type="text" :placeholder="$t('chat.leaveMessage')" class="w-full text-lg placeholder-muted" required />
				<button type="submit" class="w-24 rounded-xl bg-primary px-4 py-2 text-white">{{ $t('chat.send') }}</button>
			</form>
		</Footer>
		<!-- </Footer> -->
	</BaseLayout>
</template>

<script>
import { storeToRefs } from 'pinia'
import { ref, computed, nextTick, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import { useSharedStore } from '@/store/shared'
import { useChatStore } from '@/store/chat'
import { useCustomerStore } from '@/store/customer'

export default {
	setup() {
		const store = useStore()
		const { bind, chatMessage } = storeToRefs(useSharedStore())
		const { createMessage } = useChatStore()
		const { currentCustomer } = storeToRefs(useCustomerStore())
		const messages = computed(() => bind.value.messages)
		watch(messages, newMessage => {
			newMessage.sort(function (oldValue, newValue) {
				return oldValue.createdAt - newValue.createdAt
			})
		})

		const content = ref('')

		const error = ref(null)
		const isLoading = computed(() => store.state.loadingState)

		onMounted(() => {
			content.value = chatMessage.value
			scrollToBottom()
		})

		function scrollToBottom() {
			const element = document.getElementById('messagesContainer')
			nextTick(() => {
				element.scrollIntoView({ block: 'end' })
			})
		}

		function getDateTime(createdAt) {
			return moment(createdAt).format('YYYY-MM-DD HH-mm A')
		}

		async function submit() {
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				console.log('submit', currentCustomer.value.id)
				await createMessage(currentCustomer.value.id, content.value)
				// await store.dispatch('createMessage', { customer: currentCustomer.value.id, content: content.value })
				content.value = ''
				chatMessage.value = ''
				scrollToBottom()
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		return {
			chatMessage,
			messages,
			content,
			error,
			isLoading,
			getDateTime,
			submit,
		}
	},
}
</script>
